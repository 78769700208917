import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getTheme, transition } from "styles";
import "react-datepicker/dist/react-datepicker.css";

const InputDateWrap = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;

  .placeholderIcon {
    position: absolute;
    top: 0;
    left: 15px;
    height: 100%;
    display: ${({ placeholder }) => `${placeholder?.icon ? "flex" : "none"}`};
    align-items: center;
    background-color: ${({ enabled, placeholder }) =>
      enabled ? getTheme("cg800") : placeholder?.color || getTheme("cg300")};
    mask: ${({ placeholder }) =>
      `url(${placeholder?.icon || "url(#mask)"} ) no-repeat center`};
    img {
      opacity: 0;
    }
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: ${({ Top }) => Top && `${Top?.margin?.bottom || "8px"}`};
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: ${({ Bottom }) => Bottom && `${Bottom?.margin?.top || "8px"}`};
`;

const InputDate = styled.input`
  border: ${({ border }) =>
    `${border?.width || 1}px ${border?.style || "solid"} ${
      border?.color || getTheme("cg100")
    }`};
  border-radius: ${({ border }) => `${border?.radius || 8}px`};
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  vertical-align: 0px;
  padding: ${({ padding }) =>
    `${padding?.top || padding?.y || 12}px 
        ${padding?.right || padding?.x || 11}px 
        ${padding?.bottom || padding?.y || 12}px 
        ${padding.left}px`};
  width: 100%;
  height: 52px;
  ${transition()};

  &:focus {
    border: ${({ border }) =>
      `${border?.focus?.width || 1}px
          ${border?.focus?.style || "solid"}
          ${border?.focus?.color || getTheme("primary")}`};
  }
  &::placeholder {
    color: ${({ placeholder }) => placeholder?.color || getTheme("cg300")};
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

function Input(props) {
  const { id, placeholder, value } = props;
  const [paddingLeft, setPaddingLeft] = useState(0);
  const dateRef = useRef(null);

  const onPlaceHolderLoad = (e) => {
    setPaddingLeft(e.target.offsetWidth + 15 + 8);
  };

  return (
    <div className="Input">
      {props?.Top && (
        <Top Top={Top}>
          <div className="Left">{props?.Top?.Left}</div>
          <div className="Right">{props?.Top?.Right}</div>
        </Top>
      )}
      <InputDateWrap placeholder={placeholder} enabled={value}>
        <label
          htmlFor={id ?? "inputDate"}
          className="placeholderIcon"
          onLoad={onPlaceHolderLoad}
        >
          <img src={placeholder?.icon} alt="placeholder" />
        </label>
        <InputDate
          padding={{ left: paddingLeft }}
          placeholder={placeholder?.text}
          readOnly
          value={value}
          onClick={() => dateRef.current.focus()}
        />
        <InputDate
          style={{
            opacity: "0",
            position: "absolute",
            left: "0",
            zIndex: "-1",
          }}
          id="inputDate"
          {...props}
          type="date"
          value={value}
          padding={{ left: paddingLeft }}
          onFocus={() => dateRef.current.showPicker()}
          ref={dateRef}
        />
      </InputDateWrap>
      {props?.Bottom && (
        <Bottom Bottom={Bottom}>
          <div className="Left">{props?.Bottom?.Left}</div>
          <div className="Right">{props?.Bottom?.Right}</div>
        </Bottom>
      )}
    </div>
  );
}

export default Input;
