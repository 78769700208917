import React, { useState } from 'react';
import styled from 'styled-components';
import { getTheme, transition } from 'styles';
import { input_placeholder_clear } from 'assets';
import { useField } from 'formik';

const InputTextWrap = styled.div`
  position: relative;
  width: 100%;

  input::-webkit-input-placeholder {
    color: ${({ placeholder }) => placeholder?.color || getTheme('cg300')};
  }

  .placeholderIcon {
    position: absolute;
    top: 0;
    left: 17px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${({ placeholder }) => placeholder?.color || getTheme('cg300')};
    mask: ${({ placeholder }) => `url(${placeholder?.icon || 'url(#mask)'} ) no-repeat center`};
    img{
      opacity: 0;
    }
  }
  .placeholderClear {
    position: absolute;
    top: 0;
    right: 16px;
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  margin-bottom: ${({ Top }) => Top && `${Top?.margin?.bottom || '8px'}`};
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: ${({ Bottom }) => Bottom && `${Bottom?.margin?.top || '8px'}`};
`;

const InputText = styled.input`
    border: ${({ border }) =>
    `${border?.width || 1}px ${border?.style || "solid"} ${border?.color || getTheme('cg100')}`};
    border-radius: ${({ border }) => `${border?.radius || 8}px`};
    background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    vertical-align: 0px;
    padding: ${({ padding }) =>
    `${padding?.top || padding?.y || 12}px 
        ${padding?.right || padding?.x || 17}px 
        ${padding?.bottom || padding?.y || 12}px 
        ${(padding?.left || padding?.x || 17)}px`};
    width: 100%;
    ${transition()};

    &:focus {
      border: ${({ border }) =>
    `${border?.focus?.width || 1}px
          ${border?.focus?.style || "solid"}
          ${border?.focus?.color || getTheme('primary')}`};
    }

    &::placeholder {
      color: ${getTheme('cg300')};
    }
`;

function Input(props) {
  const [paddingLeft, setPaddingLeft] = useState(0);
  let field,meta,helpers;
  let [value, setValue] = useState(props.value);

  const onPlaceHolderLoad = (e) => {
    setPaddingLeft(e.target.offsetWidth + 17 + 10);
  }

  try {
    [field, meta, helpers] = useField(props.name);
    ({ value } = meta);
    ({ setValue } = helpers);
  } catch (error) {
    field = {};
  }

  return (
    <div className="Input">
      {props?.Top && (
        <Top Top={Top}>
          <div className="Left">{props?.Top?.Left}</div>
          <div className="Right">{props?.Top?.Right}</div>
        </Top>
      )}
      <InputTextWrap placeholder={props?.placeholder}>
        <div className="placeholderIcon" onLoad={onPlaceHolderLoad}>
          <img src={props?.placeholder?.icon} alt="" srcSet="" />
        </div>
        <InputText
          type="password"
          {...field}
          {...props}
          value={value}
          placeholder={props?.placeholder?.text}
          padding={{ left: paddingLeft }}
          onChange= {(e) => {
            setValue(e.target.value);
            props.onChange && props.onChange(e);
          }}
        />
        {value && (
          <div className="placeholderClear"
            onClick={(e) => {
              setValue('');
              props.onChange && props.onChange(e);
          }}>
            <img src={input_placeholder_clear} alt="" srcSet="" />
          </div>
        )}
      </InputTextWrap>
      {props?.Bottom && (
        <Bottom Bottom={Bottom}>
          <div className="Left">{props?.Bottom?.Left}</div>
          <div className="Right">{props?.Bottom?.Right}</div>
        </Bottom>
      )}
    </div>
  )
}

export default Input;
