import { lazy } from 'react';

function componentLoader(lazyComponent, attemptsLeft = 2) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1000);
      });
  });
}

const AdminLoginPage = lazy(() => componentLoader(() => import('pages/Admin/Login')));
const AdminPage = lazy(() => componentLoader(() => import('pages/Admin')));
const AdminHomePage = lazy(() => componentLoader(() => import('pages/Admin/Home')));
const AdminDietPage = lazy(() => componentLoader(() => import('pages/Admin/Diet')));
const AdminMerchandisePage = lazy(() => componentLoader(() => import('pages/Admin/Merchandise')));
const AdminRegularPage = lazy(() => componentLoader(() => import('pages/Admin/Regular')));
const AdminMemberPage = lazy(() => componentLoader(() => import('pages/Admin/Member')));
const AdminOrderPage = lazy(() => componentLoader(() => import('pages/Admin/Order')));
const AdminQuantityPage = lazy(() => componentLoader(() => import('pages/Admin/Quantity')));
const AdminInquiryPage = lazy(() => componentLoader(() => import('pages/Admin/Inquiry')));
const AdminNoticePage = lazy(() => componentLoader(() => import('pages/Admin/Notice')));
const AdminStepPage = lazy(() => componentLoader(() => import('pages/Admin/Step')));
const AdminWeightPage = lazy(() => componentLoader(() => import('pages/Admin/Weight')));
const AdminDepositPage = lazy(() => componentLoader(() => import('pages/Admin/Deposit')));
const AdminDeliveryFeePage = lazy(() => componentLoader(() => import('pages/Admin/DeliveryFee')));
const AdminDeadlinePage = lazy(() => componentLoader(() => import('pages/Admin/Deadline')));
const AdminGroupPage = lazy(() => componentLoader(() => import('pages/Admin/Group')));
const AdminPushAlarmPage = lazy(() => componentLoader(() => import('pages/Admin/PushAlarm')));
const AdminNotificationPage = lazy(() => componentLoader(() => import('pages/Admin/Notification')));
const AdminMyPage = lazy(() => componentLoader(() => import('pages/Admin/MyPage')));
const SupervisorFranchisePage = lazy(() => componentLoader(() => import('pages/Admin/Supervisor/Franchise')));
const SupervisorBannerPage = lazy(() => componentLoader(() => import('pages/Admin/Supervisor/Banner')));



const HomePage = lazy(() => componentLoader(() => import('pages/Home')));
const MyPage = lazy(() => componentLoader(() => import('pages/Home/MyPage')));
const AskPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Ask')));
const AskDetailPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Ask/Detail')));
const ChangePage = lazy(() => componentLoader(() => import('pages/Home/MyPage/ChangePage')));
const NoticePage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Notice')));
const NoticeDetailPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Notice/Detail')));
const PhonePage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Phone')));
const SettingsPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Settings')));
const MyStepPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/MyStep')));
const AddressPage = lazy(() => componentLoader(() => import('pages/Home/MyPage/Address')));

const NotificationPage = lazy(() => componentLoader(() => import('pages/Home/Notification')));
const WishlistPage = lazy(() => componentLoader(() => import('pages/Home/Wishlist')));
const WishlistNonePage = lazy(() => componentLoader(() => import('pages/Home/Wishlist/None')));
const OrderPage = lazy(() => componentLoader(() => import('pages/Order')));
const OrderDonePage = lazy(() => componentLoader(() => import('pages/Order/Done')));
const OrderHistoryPage = lazy(() => componentLoader(() => import('pages/Order/History')));
const OrderDetailPage = lazy(() => componentLoader(() => import('pages/Order/History/Detail')));
const OrderCancelPage = lazy(() => componentLoader(() => import('pages/Order/Cancel')));
const OrderCancelDonePage = lazy(() => componentLoader(() => import('pages/Order/Cancel/OrderCancelDone')));

const RegularPage = lazy(() => componentLoader(() => import('pages/Regular/Set')));
const RegularNotSetPage = lazy(() => componentLoader(() => import('pages/Regular/NotSet')));
const RegularNotSetOptionPage = lazy(() => componentLoader(() => import('pages/Regular/NotSet/Option')));
const RegularNotSetCalendarPage = lazy(() => componentLoader(() => import('pages/Regular/NotSet/Calendar')));
const RegularNotSetOrderPage = lazy(() => componentLoader(() => import('pages/Regular/NotSet/Order')));
const RegularNotSetDonePage = lazy(() => componentLoader(() => import('pages/Regular/NotSet/Done')));
const RegularOptionPage = lazy(() => componentLoader(() => import('pages/Regular/Set/Option')));
const ReservationDate = lazy(() => componentLoader(() => import('pages/Regular/Set/ReservationDate')));
const RegularDate = lazy(() => componentLoader(() => import('pages/Regular/Set/Date')));

const DepositPage = lazy(() => componentLoader(() => import('pages/Home/Deposit')));
const VirtualAccountPage = lazy(() => componentLoader(() => import('pages/Home/Deposit/VirtualAccount')));
const ChargingPage = lazy(() => componentLoader(() => import('pages/Home/Deposit/Charging')));
const ChargingDonePage = lazy(() => componentLoader(() => import('pages/Home/Deposit/Charging/Done')));
const LogoutPage = lazy(() => componentLoader(() => import('pages/Logout')));

const LoginPage = lazy(() => componentLoader(() => import('pages/Login')));
const TempPasswdPage = lazy(() => componentLoader(() => import('pages/Login/TempPasswd')));
const TempDonePage = lazy(() => componentLoader(() => import('pages/Login/TempPasswd/Done')));
const RegisterPage = lazy(() => componentLoader(() => import('pages/Register')));
const StartPage = lazy(() => componentLoader(() => import('pages/Start')));
const StartHomePage = lazy(() => componentLoader(() => import('pages/Start/Home')));
const WeeklyPage = lazy(() => componentLoader(() => import('pages/Diet/Weekly')));
const DietDetailPage = lazy(() => componentLoader(() => import('pages/Diet/Detail')));

const AOSPage = lazy(() => componentLoader(() => import('pages/AOS')));
const IOSPage = lazy(() => componentLoader(() => import('pages/IOS')));
const PrivacyPage = lazy(() => componentLoader(() => import('pages/Agreement/Privacy')));
const EulaPage = lazy(() => componentLoader(() => import('pages/Agreement/Eula')));

const pages = {
  AOSPage,
  IOSPage,
  PrivacyPage,
  EulaPage,
  AdminLoginPage,
  AdminPage,
  AdminHomePage,
  AdminDietPage,
  AdminMerchandisePage,
  AdminRegularPage,
  AdminMemberPage,
  AdminOrderPage,
  AdminQuantityPage,
  AdminInquiryPage,
  AdminNoticePage,
  AdminStepPage,
  AdminWeightPage,
  AdminDepositPage,
  AdminDeliveryFeePage,
  AdminDeadlinePage,
  AdminGroupPage,
  AdminPushAlarmPage,
  AdminNotificationPage,
  AdminMyPage,
  SupervisorFranchisePage,
  SupervisorBannerPage,
  HomePage,
  MyPage,
  PhonePage,
  AskPage,
  AskDetailPage,
  ChangePage,
  AddressPage,
  MyStepPage,
  NotificationPage,
  WishlistPage,
  WishlistNonePage,
  OrderPage,
  OrderDonePage,
  OrderHistoryPage,
  OrderDetailPage,
  OrderCancelPage,
  OrderCancelDonePage,
  RegularPage,
  RegularNotSetPage,
  RegularNotSetOptionPage,
  RegularNotSetCalendarPage,
  RegularNotSetOrderPage,
  RegularNotSetDonePage,
  ReservationDate,
  RegularOptionPage,
  DepositPage,
  VirtualAccountPage,
  ChargingPage,
  ChargingDonePage,
  LoginPage,
  NoticePage,
  NoticeDetailPage,
  SettingsPage,
  RegisterPage,
  StartPage,
  StartHomePage,
  RegularDate,
  WeeklyPage,
  TempPasswdPage,
  TempDonePage,
  DietDetailPage,
  LogoutPage,
};

export default pages;
