import React from 'react';
import { MobileHeader } from 'components';
import { useMobileCheck } from 'hooks';
import NavigationBar from 'components/Layouts/NavigationBar';


function LayoutContainer({ children }) {
  const isMobile = useMobileCheck();
  return (
    <>
      {isMobile ? <MobileHeader /> : ''}
      {children}
      <NavigationBar/>
    </>
  );
}

export default LayoutContainer;
