import { ic_prev, ic_close } from "assets";
import { NavLink } from "react-router-dom";
import { getHeaderHeight } from "styles";
import styled, { createGlobalStyle } from "styled-components";
import { getTheme } from "styles";
import { useRecoilValue, atom } from "recoil";

const GlobalStyle = createGlobalStyle`
  #root:before {
    content: '';
    display: block;
    min-height: 56px;
  }
`;

const HeaderWrapper = styled.div`
  padding: 0 16px;
  width: 100vw;
  height: ${getHeaderHeight()}px;
  background: ${getTheme("cg00")};
  display: block;
  position: fixed;
  top: 0;
  z-index: 999999;
`;

const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${getTheme("cg800")};
  font-weight: 700;
  & img {
    vertical-align: top;
  }
  & .middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
export const headerParams = atom({
  key: "headerParams", // unique ID (다른 atoms/selectors을 구별하기 위해서)
  default: {
    visible: true,
    left: {
      content: ic_prev,
      to: "",
    },
    middle: {
      content: "",
      to: "",
    },
    right: {
      content: ic_close,
      to: "",
    },
  },
});

function MobileHeader() {
  let { visible = true, left, middle, right } = useRecoilValue(headerParams);
  left = (left && !Object.keys(left).length) || {
    content: <img src={ic_prev} alt="prev" />,
    to: "back",
    ...left,
  };
  middle = (middle && !Object.keys(middle).length) || {
    content: <img src={ic_prev} alt="prev" />,
    ...middle,
  };
  right = (right && !Object.keys(right).length) || {
    content: <img src={ic_close} alt="prev" />,
    to: "back",
    ...right,
  };

  const setHeader = (e) => {
    if (visible === false) return;

    return e?.onClick ? (
      <div onClick={e.onClick}>{e.content}</div>
    ) : e?.to ? (
      <NavLink to={e.to === "back" ? -1 : e.to}>{e.content}</NavLink>
    ) : (
      e?.content
    );
  };
  return visible ? (
    <>
      <GlobalStyle />
      <HeaderWrapper id="mobile-header">
        <InnerWrapper>
          <div className="c-white">{setHeader(left)}</div>
          <div className="middle">{setHeader(middle)}</div>
          <div className="c-white">{setHeader(right)}</div>
        </InnerWrapper>
      </HeaderWrapper>
    </>
  ) : null;
}

export default MobileHeader;
