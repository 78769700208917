import React from 'react';
import AppRouter from './routers/AppRouter';
import GlobalStyles from './styles/GlobalStyles';
import Helmet from 'react-helmet';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot> 
      <Helmet>
        <title>아기숟가락</title>
      </Helmet>
      <AppRouter />
      <GlobalStyles />
    </RecoilRoot>
  );
}

export default App;
