import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      // return <div>{this.state.error.toString()}</div>;
      return <div></div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;