import React from 'react';
import styled from 'styled-components';
import { getTheme, defaultButtonConfig } from 'styles';

const NormalButton = styled.button`
  ${({ background, hover }) => defaultButtonConfig(background, hover)};
  ${({ negative }) => (negative && 'background:none')}; 
  justify-content: center;
  align-items: center;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width ? width + 'px' : '100%'}`}; 
  border: ${({ negative }) => (negative && '1px solid ' + getTheme('primary')) || 'none'};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  border-color: ${({ borderColor }) => borderColor || getTheme('primary')};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  user-select: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
`;

function Button({ children, to, color, background, fontSize = 16, fontWeight = 700, height = 56, borderRadius = 8, ...rest }) {
  return (
    <NormalButton
      color={color}
      background={background}
      fontSize={fontSize}
      fontWeight={fontWeight}
      height={height}
      borderRadius={borderRadius}
      {...rest}
    >
      {children}
    </NormalButton>
  );
}

export default Button;
