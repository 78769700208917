import { default as InputText } from './Text';
import { default as InputPassword } from './Password';
import { default as InputDate } from './Date';

function Input(props) {
  switch (props.type) {
    case 'text':
      return <InputText {...props} />;

    case 'password':
      return <InputPassword {...props} />

    case 'date':
      return <InputDate {...props} />

    default:
      break;
  }
}

export default Input;
