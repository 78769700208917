import {
  ic_home,
  ic_home_inactive,
  ic_mypage,
  ic_mypage_active,
  ic_regular_delivery,
  ic_regular_delivery_active,
} from "assets";
import styled from "styled-components";
import { getTheme } from "styles";
import { useLocation, useNavigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  #root {
    height: calc(var(--vh) * 100 - 80px);
    margin-bottom: 80px;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  z-index: 99999;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: fixed;
  bottom: 0;
  background: ${getTheme("cg00")};
  border-top: 1px solid ${getTheme("cg40")};
  font-weight: 500;
  height: 80px;
`;

const FooterButton = styled.button.attrs({ type: "button" })`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 4px;
  width: calc(100vw / 3);
  text-align: center;
  letter-spacing: 0.5px;
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  color: ${({ isActive }) =>
    isActive === "active" ? getTheme("primary") : getTheme("cg500")};
  div {
    height: 32px;
  }
  img {
    padding: 4px;
  }
  .active {
    width: 64px;
    background: ${getTheme("primary-light")};
    border-radius: 20px;
  }
  span {
    width: 100%;
  }
`;

function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    !location?.state?.category &&
    !location?.state?.step &&
    ["/home", "/regular", "/regular/notset", "/mypage"].find(
      (e) => e === location.pathname
    ) && (
      <Container>
        <GlobalStyle />
        <FooterButton
          isActive={location.pathname === "/home" ? "active" : "inactive"}
          onClick={() => navigate("/home")}
        >
          <div className={location.pathname === "/home" ? "active" : null}>
            <img
              src={location.pathname === "/home" ? ic_home : ic_home_inactive}
              alt="home"
            />
          </div>
          <span style={{ fontWeight: "500" }}>홈</span>
        </FooterButton>
        <FooterButton
          isActive={
            location.pathname === "/regular" ||
            location.pathname === "/regular/notset"
              ? "active"
              : "inactive"
          }
          onClick={() => navigate("/regular")}
        >
          <div
            className={
              location.pathname === "/regular" ||
              location.pathname === "/regular/notset"
                ? "active"
                : null
            }
          >
            <img
              src={
                location.pathname === "/regular" ||
                location.pathname === "/regular/notset"
                  ? ic_regular_delivery_active
                  : ic_regular_delivery
              }
              alt="regular_delivery"
            />
          </div>
          <span style={{ fontWeight: "500" }}>정기배송</span>
        </FooterButton>
        <FooterButton
          isActive={location.pathname === "/mypage" ? "active" : "inactive"}
          onClick={() => navigate("/mypage")}
        >
          <div className={location.pathname === "/mypage" ? "active" : null}>
            <img
              src={
                location.pathname === "/mypage" ? ic_mypage_active : ic_mypage
              }
              alt="mypage"
            />
          </div>
          <span style={{ fontWeight: "500" }}>내 정보</span>
        </FooterButton>
      </Container>
    )
  );
}

export default NavigationBar;
