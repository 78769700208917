import { ic_dropdown } from "assets";
import styled from "styled-components";
import { getTheme, transition } from "styles";

const Container = styled.div`
  position: relative;
`;

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${getTheme("cg100")};
  border-radius: 8px;
  padding: 12px 12px 12px 16px;
  ${transition()};
  [name="step"] {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: ${getTheme("cg800")};
    margin: 0;
    padding: 0;

    &::placeholder {
      color: ${getTheme("cg300")};
    }
  }
  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    align-items: center;
  }
  &:focus-within {
    border: 1px solid ${getTheme("primary")};

    & + div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Options = styled.div`
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  transform: translateY(100%);
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 0;
  display: flex;
  flex-flow: column;
  ${transition()};
  visibility: hidden;
  opacity: 0;
  max-height: 200px;
  overflow: scroll;

  [data-value] {
    padding: 12px 16px;
  }

  [data-value]:not(:last-child) {
    border-bottom: 1px solid ${getTheme("cg60")};
  }
`;

function Dropdown(props) {
  const { id = "step", list } = props;
  const placeholder = props.placeholder || "";

  return (
    <Container>
      <Select>
        <input
          id={id}
          name="step"
          type="text"
          disabled={
            props.disabled
          }
          readOnly
          placeholder={placeholder}
          value={list?.[props.active]?.view || ""}
        />
        <label htmlFor={id} className="icon">
          <img src={ic_dropdown} alt="" />
        </label>
      </Select>
      <Options>
        {list &&
          list.map((item, index) => (
            <div
              key={index}
              data-value={item.value}
              onClick={() => {
                props.onChange(item.value);
              }}
            >
              {item.view}
            </div>
          ))}
      </Options>
    </Container>
  );
}

export default Dropdown;
