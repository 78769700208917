import styled from "styled-components";
import { getTheme } from "styles";

const Conatiner = styled.div`
  border-top: 1px solid ${getTheme("cg40")};
  background: ${getTheme("cg20")};
  height: 8px;
`;

function Partition() {
  return <Conatiner />;
}

export default Partition;
