import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import React from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
const rootNode = document.getElementById('root');

function getLibrary(provider) {
  const library = new Web3Provider(provider, "any");
  return library;
}

ReactDOM.createRoot(rootNode).render(
  // <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  // </React.StrictMode>
);

reportWebVitals();