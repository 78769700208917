import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutContainer from '../LayoutContainer';
import RouteList from './RouteList';
import ErrorBoundary from './ErrorBoundary';

function AppRouter() {
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`); 
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("ontouchstart", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("ontouchstart", handleResize);
    }
  }, []);
  
  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <LayoutContainer>
          <Routes>
            <Route path='/admin/login' element={<RouteList.AdminLoginPage />} />
            <Route path='admin' element={<RouteList.AdminPage />}>
              <Route index element={<RouteList.AdminHomePage />} />
              <Route path='diet' element={<RouteList.AdminDietPage />} />
              <Route path='merchandise' element={<RouteList.AdminMerchandisePage />} />
              <Route path='member' element={<RouteList.AdminMemberPage />} />
              <Route path='regular' element={<RouteList.AdminRegularPage />} />
              <Route path='order' element={<RouteList.AdminOrderPage />} />
              <Route path='quantity' element={<RouteList.AdminQuantityPage />} />
              <Route path='inquiry' element={<RouteList.AdminInquiryPage />} />
              <Route path='notice' element={<RouteList.AdminNoticePage />} />
              <Route path='step' element={<RouteList.AdminStepPage />} />
              <Route path='weight' element={<RouteList.AdminWeightPage />} />
              <Route path='deposit' element={<RouteList.AdminDepositPage />} />
              <Route path='deliveryfee' element={<RouteList.AdminDeliveryFeePage />} />
              <Route path='deadline' element={<RouteList.AdminDeadlinePage />} />
              <Route path='group' element={<RouteList.AdminGroupPage />} />
              <Route path='pushalarm' element={<RouteList.AdminPushAlarmPage />} />
              <Route path='notification' element={<RouteList.AdminNotificationPage />} />
              <Route path='mypage' element={<RouteList.AdminMyPage />} />
              <Route path='supervisor/franchise' element={<RouteList.SupervisorFranchisePage />} />
              <Route path='supervisor/banner' element={<RouteList.SupervisorBannerPage />} />
            </Route>
            <Route exact path='/aos/:deviceToken' element={<RouteList.AOSPage />} />
            <Route exact path='/ios/:deviceToken' element={<RouteList.IOSPage />} />
            <Route path='/' element={<RouteList.StartPage />} >
              <Route index element={<RouteList.StartHomePage />} />
              <Route exact path='/home' element={<RouteList.HomePage />} />

              <Route exact path='/mypage' element={<RouteList.MyPage />} />
              <Route exact path='/mypage/phone' element={<RouteList.PhonePage />} />
              <Route exact path='/mypage/mystep' element={<RouteList.MyStepPage />} />
              <Route exact path='/mypage/ask' element={<RouteList.AskPage />} />
              <Route exact path='/mypage/ask/detail' element={<RouteList.AskDetailPage />} />
              <Route exact path='/mypage/change' element={<RouteList.ChangePage />} />
              <Route exact path='/mypage/notice' element={<RouteList.NoticePage />} />
              <Route exact path='/mypage/notice/detail' element={<RouteList.NoticeDetailPage />} />
              <Route exact path='/mypage/settings' element={<RouteList.SettingsPage />} />
              <Route exact path='/mypage/address' element={<RouteList.AddressPage />} />

              <Route exact path='/notification' element={<RouteList.NotificationPage />} />
              <Route exact path='/wishlist' element={<RouteList.WishlistPage />} />
              <Route exact path='/wishlist/none' element={<RouteList.WishlistNonePage />} />

              <Route exact path='/detail/:dt/:product_code/:product_category' element={<RouteList.DietDetailPage />} />

              <Route exact path='/order' element={<RouteList.OrderPage />} />
              <Route exact path='/order/done' element={<RouteList.OrderDonePage />} />
              <Route exact path='/order/history' element={<RouteList.OrderHistoryPage />} />
              <Route exact path='/order/history/:code' element={<RouteList.OrderDetailPage />} />
              <Route exact path='/order/history/:code/cancel' element={<RouteList.OrderCancelPage />} />
              <Route exact path='/order/history/cancel/done' element={<RouteList.OrderCancelDonePage />} />

              <Route exact path='/regular/*' element={<RouteList.RegularPage />} />
              <Route exact path='/regular/notset' element={<RouteList.RegularNotSetPage />} />
              <Route exact path='/regular/notset/option' element={<RouteList.RegularNotSetOptionPage />} />
              <Route exact path='/regular/notset/calendar' element={<RouteList.RegularNotSetCalendarPage />} />
              <Route exact path='/regular/notset/order' element={<RouteList.RegularNotSetOrderPage />} />
              <Route exact path='/regular/notset/done' element={<RouteList.RegularNotSetDonePage />} />
              <Route exact path='/regular/option' element={<RouteList.RegularOptionPage />} />
              <Route exact path='/regular/reservation' element={<RouteList.ReservationDate />} />
              <Route exact path='/regular/date' element={<RouteList.RegularDate />} />
              <Route exact path='/deposit' element={<RouteList.DepositPage />} />
              <Route exact path='/deposit/virtualaccount' element={<RouteList.VirtualAccountPage />} />
              <Route exact path='/deposit/charging' element={<RouteList.ChargingPage />} />
              <Route exact path='/deposit/charging/done' element={<RouteList.ChargingDonePage />} />

              <Route exact path='/logout' element={<RouteList.LogoutPage />} />

              <Route exact path='/login' element={<RouteList.LoginPage />} />
              <Route exact path='/login/temppasswd' element={<RouteList.TempPasswdPage />} />
              <Route exact path='/login/temppasswd/done' element={<RouteList.TempDonePage />} />

              <Route exact path='/register' element={<RouteList.RegisterPage />} />
              <Route exact path='/weekly' element={<RouteList.WeeklyPage />} />
              
              <Route exact path='/agreement/privacy' element={<RouteList.PrivacyPage />} />
              <Route exact path='/agreement/eula' element={<RouteList.EulaPage />} />
            </Route>
          </Routes>
        </LayoutContainer>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppRouter;
