import { css } from 'styled-components';
import { darken } from 'polished';
import { getTheme } from './theme';
export { default as theme } from './theme';
export { getTheme, getFont } from './theme';

export const transition = (timing = 0.2) =>
  css`
    -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
  
export const getTransition = (props) =>{
  let result = "";
  if(props){
    let timing = 0.2;
    let easing = 'cubic-bezier(0.215, 0.61, 0.355, 1)';
    let transitions = [
      '-webkit-transition',
      '-moz-transition',
      '-ms-transition',
      '-o-transition',
      'transition'
    ]
    
    let temp = [];
    for(let option in props){
      temp.push(`${option} ${props[option].timing||timing}s ${props[option].easing||easing}`);
      temp.join(',');
    }
    for(let transition in transitions){
      result += `${transitions[transition]}: ${temp};`;
    }
  } else {
    result = css`
      -webkit-transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
    `;
  }
  return result;
}

export const defaultButtonConfig = (backgroundColor = getTheme('primary'), hover = true, negative = false) => css`
  ${transition()};
  outline: none;
  border:none;
  background-color: ${backgroundColor};
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${hover && darken(0.03, backgroundColor)};
  }
  &:disabled {
    background-color: ${getTheme('cg40')};
  }
`;

export const fontSizer = (size, minSize) => {
  return css`
    font-size: ${size + 'px'};
  `;
};

export const getHeaderHeight = (headerHeight=56) => {
  return headerHeight;
}