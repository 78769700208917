const theme = {
    primary: '#FF8000',
    'primary-light': '#FFF5EB',
    secondary: '#0080FF',
    'secondary-light': '#EBF5FF',
    cg900: '#12161A',
    cg800: '#292E33',
    cg700: '#3D454D',
    cg600: '#525C66',
    cg500: '#6C7680',
    cg300: '#98A5B3',
    cg100: '#DAE0E6',
    cg60: '#E4EAF0',
    cg40: '#EDF1F5',
    cg20: '#F7F9FA',
    cg00: '#FFFFFF',
    background: '#FFFFFF',
    'red-error': '#FF0000',
};

const fonts = {
    default: `'Spoqa Han Sans Neo'`,
    serif: `'Noto Serif KR'; serif`,
    number: `'Roboto'`,
};

export const getTheme = (key) => {
    return key && theme[key] ? theme[key] : theme.primary;
};

export const getFont = (key) => {
    return key && fonts[key] ? fonts[key] : fonts.default;
};

export default theme;